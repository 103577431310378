<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      I'm a software developer in New Zealand. I make open-source projects and
      write about code, design, and life. I like music, sci-fi, soccer, and
      gaming. <br /><br />You can read more about me, experience, skills and
      much more at the link below: <br />
      <br />

      <a href="https://resume.io/r/LWCDWYlIH" target="_blank" rel="noopener"
        >My Resume</a
      >.
    </p>
    <ul>
      <li>
        <a
          href="https://www.linkedin.com/in/renatorocha1/"
          target="_blank"
          rel="noopener"
          >Linkedin</a
        >
      </li>
      <li>
        <a href="https://github.com/renatorocha1" target="_blank" rel="noopener"
          >Github</a
        >
      </li>
    </ul>
    <p>Renato Rocha - All Rights Reserved</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  padding-top: 50px;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
